<template>
  <section v-if="storeAreas.area" class="senha" :class="tela.layout">
    <div class="imagem" :style="`background-image: url('${tela.imagemFundo || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/background-vertical.svg'}')`"></div>
    <div class="conteudo" :style="conteudoEstilo">
      <div class="titulo">
        <img :src="`${tela.imagemLogo || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/logo.svg'}`" />
        <p :style="tituloEstilo">{{ tela.textoTitulo || 'Esse é o título da sua tela de recuperação de senha' }}</p>
      </div>
      <div class="campos">
        <label :style="labelEstilo">E-mail da conta</label>
        <div class="inputDefault">
          <input type="email" spellCheck="false" placeholder="email@email.com" autoComplete="email" :style="inputEstilo" />
        </div>
        <button class="principal" :style="botaoEstilo">
          {{ tela.textoBotaoAlterar || 'Alterar Senha' }}
          <span></span>
        </button>
        <div class="opcoes" v-if="tela.linkBotaoExtra">
          <a target="_blank" :href="tela.linkBotaoExtra" :style="botoesExtrasEstilo">{{ tela.textoBotaoExtra || 'Botão Extra' }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStoreAreas } from '@stores'

const storeAreas = useStoreAreas()

const tela = computed(() => storeAreas.area?.personalizacao?.telaLogin || {})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulo,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const labelEstilo = computed(() => ({
  color: tela.value.corLabels,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const inputEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoCampos,
  color: tela.value.corTextoCampos,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botaoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotao,
  color: tela.value.corTextoBotao,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botoesExtrasEstilo = computed(() => ({
  color: tela.value.corBotoesExtras,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

function atualizarFonte() {
  const fonte = tela.value.fonte || 'Inter'
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  const url = estilo ? `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}:wght@${estilo.replace(/\s+/g, '')}&display=swap` : `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

function renderizarFonte() {
  const fonte = tela.value.fonte
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  return {
    fontFamily: nomeFonte || 'Figtree',
    fontWeight: estilo || '400'
  }
}

watch(
  () => tela.value.corFundo,
  (novaCorFundo) => {
    document.body.style.backgroundColor = novaCorFundo
    document.documentElement.style.backgroundColor = novaCorFundo
  },
  { immediate: true }
)

watch(
  () => storeAreas.area?.personalizacao?.telaLogin,
  (novoValor, valorAntigo) => {
    if (novoValor !== valorAntigo) {
      atualizarFonte()
    }
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
section.senha {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 100dvh;
}

section.senha.dois {
  justify-content: center;
  align-items: center;
  padding: 80px;
}

section.senha.dois .imagem {
  width: 100%;
}

section.senha.dois .conteudo {
  width: 100%;
  max-width: 500px;
  min-height: auto;
  padding: 60px 50px;
  border-radius: 10px;
}

section.senha.tres {
  justify-content: center;
  align-items: center;
  padding: 80px;
}

section.senha.tres .imagem {
  width: 100%;
}

section.senha.tres .conteudo .titulo {
  width: 50%;
}

section.senha.tres .conteudo .campos {
  width: 50%;
  max-width: 50%;
}

section.senha.tres .conteudo {
  flex-direction: row;
  width: 100%;
  min-height: calc(100dvh - 160px);
  padding: 60px 50px;
  border-radius: 10px;
}

.imagem {
  width: 50%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
  min-height: 100dvh;
  z-index: 2;
}

.conteudo .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conteudo .titulo img {
  width: 100%;
  max-width: 150px;
  margin: 0 0 20px 0;
}

.conteudo .titulo p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 350px;
  margin: 0 0 20px 0;
}

.conteudo .campos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
}

.conteudo label {
  font-family: var(--fonte-tela);
}

.conteudo .inputDefault {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 0 20px 0;
}

.conteudo .inputDefault input {
  padding: 25px;
  font-family: var(--fonte-tela);
  color: #262836;
  background-color: #ffffff;
  border: none;
}

.conteudo .inputDefault input::placeholder {
  opacity: 1;
}

.conteudo label {
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin: 0 0 10px 0;
  color: #262836;
}

.conteudo button.principal {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #ffffff;
  background-color: #adb4c9;
  width: 100%;
  max-width: 400px;
  padding: 25px 20px;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}

.conteudo button.principal:hover {
  opacity: 0.7;
}

.conteudo .opcoes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 30px 0 0 0;
}

.conteudo .opcoes a {
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #262836;
  background-color: transparent;
  line-height: 1.5;
  text-align: center;
  margin: 0 10px;
  transition: all 0.3s;
}

.conteudo .opcoes a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 1000px) {
  section.senha {
    flex-direction: column;
  }

  section.senha.dois {
    padding: 60px 20px;
  }

  section.senha.dois .imagem {
    width: 100%;
  }

  section.senha.dois .conteudo {
    padding: 50px 30px;
  }

  section.senha.tres {
    padding: 60px 30px;
  }

  section.senha.tres .conteudo .titulo {
    width: 100%;
    padding: 40px 0 0 0;
  }

  section.senha.tres .conteudo .campos {
    width: 100%;
    max-width: 100%;
  }

  section.senha.tres .conteudo {
    flex-direction: column-reverse;
    width: 100%;
    min-height: auto;
    padding: 40px 30px;
  }

  .imagem {
    width: 100%;
  }

  .conteudo {
    width: 100%;
    padding: 0 30px;
  }
}
</style>
