<template>
  <section v-if="storeAreas.area" class="aula" :style="conteudoEstilo">
    <div class="loading" :style="conteudoEstilo">
      <span :style="carregamentoEstilo"></span>
    </div>
    <div class="tela">
      <div class="conteudo" :class="{ maior: storeAulas.aulas?.length === 1 }">
        <div class="principal">
          <!-- Vídeo -->
          <div class="video">
            <div class="tamanho">
              <iframe :src="`${storeAulas.aula?.urlVideo || ''}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
            </div>
          </div>
          <!-- Opções -->
          <div class="opcoes">
            <button class="voltar" :style="textareaEstilo" @click="voltar()">
              <Svgs nome="seta" :style="duracaoSvgEstilo" />
              Voltar
            </button>
            <div class="duracao" v-if="storeAulas.aula?.duracao">
              <Svgs nome="relogio" :style="duracaoSvgEstilo" />
              <p :style="duracaoTextoEstilo">{{ formatarDuracao(storeAulas.aula?.duracao) }} de aula</p>
            </div>
            <button class="concluir" @click="concluirAula()" :class="{ concluido: state.aulaConcluida }" :style="botaoComentarEstilo">
              <Svgs :style="botaoComentarSvgEstilo" :nome="state.aulaConcluida ? 'check' : 'circulo'" />
              {{ state.aulaConcluida ? 'Concluída' : 'Concluir Aula' }}
            </button>
          </div>
          <!-- Nome -->
          <div class="nome">
            <h3 :style="tituloEstilo">{{ storeAulas.aula?.nome }}</h3>
            <p :style="descricaoEstilo">{{ storeAulas.aula?.descricao }}</p>
          </div>
          <!-- Links da Aula -->
          <div class="links" v-if="storeAulas.aula?.links?.length > 0">
            <h3 :style="tituloEstilo">Links</h3>
            <a v-for="link in storeAulas.aula?.links" :href="link.url" target="_blank">
              <Svgs :style="linkSvgEstilo" nome="link" />
              <p :style="linkTextoEstilo">{{ link.nome }}</p>
            </a>
          </div>
          <!-- Arqivos da Aula -->
          <div class="arquivos" v-if="storeAulas.aula?.arquivos?.length > 0">
            <h3 :style="tituloEstilo">Arquivos</h3>
            <a v-for="arquivo in storeAulas.aula?.arquivos" :href="arquivo.url" target="_blank">
              <Svgs :style="arquivoSvgEstilo" nome="arquivo" />
              <p :style="arquivoTextoEstilo">{{ arquivo.nome }} {{ arquivo.peso }}</p>
            </a>
          </div>
        </div>

        <div class="comentarios">
          <div class="comentar">
            <div class="input">
              <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/perfil-placeholder.png'}')`"></div>
              <textarea :style="textareaEstilo" v-model="state.textoComentario" rows="1" spellcheck="false" placeholder="Adicione um comentário" v-redimensionar-textarea @focus="mostrarBotoes" @blur="ocultarBotoes"></textarea>
            </div>
            <div class="botoes" v-if="state.mostrarBotoes">
              <button class="cancelar" :style="textoDataComentarioEstilo" @click="cancelarComentario">Cancelar</button>
              <button class="enviar" :style="botaoComentarEstilo" @click="comentar">Comentar</button>
            </div>
          </div>

          <div class="comentario" v-for="comentario in storeComentarios.comentarios" :id="comentario.idComentarioPrincipal" :key="comentario._id">
            <div class="foto" :style="`background-image: url('${comentario.autor?.aluno?.imagemPerfil || comentario.autor?.produtor?.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/perfil-placeholder.png'}')`"></div>
            <div class="nome">
              <h4 :style="textoComentarios" v-if="comentario.autor?.produtor">ADM</h4>
              <h3 :style="textoComentarios">{{ comentario.autor?.aluno?.nome || comentario.autor?.produtor?.nome }}</h3>
              <p :style="textoDataComentarioEstilo">{{ formatarData(comentario.dataRegistro) }}</p>
            </div>
            <div class="texto">
              <p :style="textoComentarios">{{ comentario.texto }}</p>
            </div>

            <button class="responder" :style="botaoResponderEstilo" @click="mostrarRespondendo(comentario._id)">Responder</button>

            <div class="respondendo" v-if="state.comentarioRespondendo === comentario._id">
              <div class="input">
                <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/perfil-placeholder.png'}')`"></div>
                <textarea :style="textareaEstilo" v-model="state.textoResposta" rows="1" spellcheck="false" placeholder="Adicione um comentário" v-redimensionar-textarea></textarea>
              </div>
              <div class="botoes">
                <button class="cancelar" :style="textoDataComentarioEstilo" @click="cancelarResposta">Cancelar</button>
                <button class="enviar" :style="botaoComentarEstilo" @click="responder(comentario._id)">Comentar</button>
              </div>
            </div>

            <div class="resposta" v-for="resposta in comentario.respostas" :id="resposta._id" :key="resposta._id">
              <div class="foto" :style="`background-image: url('${resposta.autor?.aluno?.imagemPerfil || resposta.autor?.produtor?.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/perfil-placeholder.png'}')`"></div>
              <div class="nome">
                <h4 :style="textoComentarios" v-if="resposta.autor?.produtor">ADM</h4>
                <h3 :style="textoComentarios">{{ resposta.autor?.aluno?.nome || resposta.autor?.produtor?.nome }}</h3>
                <p :style="textoDataComentarioEstilo">{{ formatarData(resposta.dataRegistro) }}</p>
              </div>
              <div class="texto">
                <p :style="textoComentarios">{{ resposta.texto }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="aulas" v-if="storeAulas.aulas?.length >= 2">
        <div class="item" v-for="(aula, index) in storeAulas.aulas" :key="aula._id">
          <div class="capa" :style="`background-image: url('${aula.imagemCapa || 'https://central-statics.nyc3.digitaloceanspaces.com/levelmember/imagens/default/background-vertical.svg'}')`"></div>
          <div class="info" :style="aulaEstilo">
            <h5 :style="posicaoAulaEstilo">{{ index + 1 }}º</h5>
            <div class="nome">
              <p :style="duracaoAulaEstilo">{{ formatarDuracaoSimples(aula.duracao) }}</p>
              <h3 :style="textoAulaEstilo">{{ formatarTitulo(aula.nome) }}</h3>
            </div>
            <div class="botoes">
              <button class="acessar" :style="fundoBotaoAulaEstilo" @click="irParaAula(storeAreas.area?.hotlink, storeCursos.curso?.hotlink, storeModulos.modulo?.hotlink, aula.hotlink)">
                <Svgs :style="svgBotaoAulaEstilo" nome="seta" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, computed, watch, onMounted, inject } from 'vue'
import { useStoreAreas, useStoreAulas, useStoreAulasConcluidas, useStoreCursos, useStoreModulos, useStoreComentarios, useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeAulas = useStoreAulas()
const storeAulasConcluidas = useStoreAulasConcluidas()
const storeCursos = useStoreCursos()
const storeModulos = useStoreModulos()
const storeComentarios = useStoreComentarios()
const storePerfil = useStorePerfil()
const emitter = inject('emitter')
const router = useRouter()

const state = reactive({
  textoComentario: '',
  textoResposta: '',
  loading: false,
  mostrarBotoes: false,
  mostrarBotoesResposta: false,
  aulaConcluida: false,
  comentarioRespondendo: null
})

const tela = computed(() => storeAreas.area?.personalizacao?.telaAula || {})

const conteudoEstilo = computed(() => ({
  backgroundColor: tela.value.corFundo
}))

const tituloEstilo = computed(() => ({
  color: tela.value.corTitulo,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const carregamentoEstilo = computed(() => ({
  borderColor: tela.value.corTitulo
}))

const descricaoEstilo = computed(() => ({
  color: tela.value.corDescricao,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const duracaoSvgEstilo = computed(() => ({
  fill: tela.value.corDuracao
}))

const duracaoTextoEstilo = computed(() => ({
  color: tela.value.corDuracao,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const linkSvgEstilo = computed(() => ({
  fill: tela.value.corLinks
}))

const linkTextoEstilo = computed(() => ({
  color: tela.value.corLinks,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const arquivoSvgEstilo = computed(() => ({
  fill: tela.value.corArquivos
}))

const arquivoTextoEstilo = computed(() => ({
  color: tela.value.corArquivos,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const posicaoFundoEstilo = computed(() => ({
  backgroundColor: tela.value.corPosicaoFundo
}))

const posicaoTextoEstilo = computed(() => ({
  color: tela.value.corPosicaoTexto,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const textareaEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoCampoComentario,
  color: tela.value.corTextoCampoComentario,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botaoComentarEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotaoComentar,
  color: tela.value.corTextoBotaoComentar,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const botaoComentarSvgEstilo = computed(() => ({
  fill: tela.value.corTextoBotaoComentar
}))

const botaoResponderEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotaoResponder,
  color: tela.value.corTextoBotaoResponder,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const textoComentarios = computed(() => ({
  color: tela.value.corTextosComentarios,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const textoDataComentarioEstilo = computed(() => ({
  color: tela.value.corTextoDataComentario,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const aulaEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoAula
}))

const duracaoAulaEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoDuracaoAula,
  color: tela.value.corTextoDuracaoAula,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const posicaoAulaEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoPosicaoAula,
  color: tela.value.corTextoPosicaoAula,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

const fundoBotaoAulaEstilo = computed(() => ({
  backgroundColor: tela.value.corFundoBotaoAula
}))

const svgBotaoAulaEstilo = computed(() => ({
  fill: tela.value.corSvgBotaoAula
}))

const textoAulaEstilo = computed(() => ({
  color: tela.value.corTextoAula,
  fontFamily: renderizarFonte().fontFamily,
  fontWeight: renderizarFonte().fontWeight
}))

function atualizarFonte() {
  const fonte = tela.value.fonte || 'Inter'
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  const url = estilo ? `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}:wght@${estilo.replace(/\s+/g, '')}&display=swap` : `https://fonts.googleapis.com/css2?family=${nomeFonte.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }
}

function renderizarFonte() {
  const fonte = tela.value.fonte
  const [nomeFonte, estilo] = fonte.split(',').map((item) => item.trim())

  return {
    fontFamily: nomeFonte || 'Figtree',
    fontWeight: estilo || '400'
  }
}

function voltar() {
  if (storeAulas.aulas?.length === 1) {
    const hotlink = storeAreas.area?.hotlink
    if (dominioPersonalizado()) {
      router.push(`/curso/${storeCursos.curso?.hotlink}`)
    } else if (hotlink) {
      router.push(`/${hotlink}/curso/${storeCursos.curso?.hotlink}`)
    }
  } else {
    const hotlink = storeAreas.area?.hotlink
    if (dominioPersonalizado()) {
      router.push(`/curso/${storeCursos.curso?.hotlink}/${storeModulos.modulo?.hotlink}`)
    } else if (hotlink) {
      router.push(`/${hotlink}/curso/${storeCursos.curso?.hotlink}/${storeModulos.modulo?.hotlink}`)
    }
  }
}

function formatarTitulo(titulo) {
  if (titulo.length <= 50) {
    return titulo
  }
  return titulo.substring(0, 50) + '...'
}

async function carregarAulas() {
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id

  if (idCurso && idModulo) {
    storeAulas.receberTodos(idCurso, idModulo)
  }
}

const mostrarBotoes = () => {
  state.mostrarBotoes = true
}

const ocultarBotoes = () => {
  if (state.textoComentario === '') {
    state.mostrarBotoes = false
  }
}

const cancelarComentario = () => {
  state.textoComentario = ''
  state.mostrarBotoes = false
}

const mostrarRespondendo = (idComentario) => {
  state.comentarioRespondendo = idComentario
  state.mostrarBotoesResposta = true
}

const cancelarResposta = () => {
  state.textoResposta = ''
  state.mostrarBotoesResposta = false
  state.comentarioRespondendo = null
}

async function comentar() {
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  const idAula = storeAulas.aula?._id

  const payload = {
    texto: state.textoComentario,
    link: 'https://mber.live'
  }

  if (idCurso && idModulo && idAula) {
    const comentadoOk = await storeComentarios.comentar(idCurso, idModulo, idAula, payload)
    if (comentadoOk !== true) return

    state.textoComentario = ''
    state.mostrarBotoes = false
    emitter.emit('atualizarComentarios')
  }
}

async function responder(idComentario) {
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  const idAula = storeAulas.aula?._id

  const payload = {
    texto: state.textoResposta,
    link: 'https://mber.live'
  }

  if (idCurso && idModulo && idAula && idComentario) {
    const respondidoOk = await storeComentarios.responder(idCurso, idModulo, idAula, idComentario, payload)
    if (respondidoOk !== true) return

    state.textoResposta = ''
    state.mostrarBotoesResposta = false
    state.comentarioRespondendo = null
    emitter.emit('atualizarComentarios')
  }
}

async function carregarComentarios() {
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  const idAula = storeAulas.aula?._id

  if (idCurso && idModulo && idAula) {
    const carregadoOk = await storeComentarios.receberTodos(idCurso, idModulo, idAula)
    if (carregadoOk !== true) return
  }
}

async function carregarAulasConcluidas() {
  const idCurso = storeCursos.curso?._id

  if (idCurso) {
    const carregadoOk = await storeAulasConcluidas.receberTodos(idCurso)
    if (carregadoOk !== true) return
    verificarAulaConcluida()
  }
}

function verificarAulaConcluida() {
  const aulaAtualId = storeAulas.aula?._id

  if (aulaAtualId && storeAulasConcluidas.aulasConcluidas && storeAulasConcluidas.aulasConcluidas.some((aula) => aula.idAula === aulaAtualId)) {
    state.aulaConcluida = true
  } else {
    state.aulaConcluida = false
  }
}

function formatarDuracao(totalMinutos) {
  if (totalMinutos >= 60) {
    const horas = Math.floor(totalMinutos / 60)
    const minutos = (totalMinutos % 60).toString().padStart(2, '0')
    return `${horas}h${minutos}`
  }
  return `${totalMinutos}min`
}

function formatarDuracaoSimples(totalMinutos) {
  const totalSegundos = totalMinutos * 60
  const horas = Math.floor(totalSegundos / 3600)
  const minutos = Math.floor((totalSegundos % 3600) / 60)
  const segundos = totalSegundos % 60

  if (horas > 0) {
    return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}:${String(segundos).padStart(2, '0')}`
  } else {
    return `${String(minutos).padStart(2, '0')}:${String(segundos).padStart(2, '0')}`
  }
}

function formatarData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há menos de um minuto'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()
    const hours = String(dataNotificacao.getHours()).padStart(2, '0')
    const minutes = String(dataNotificacao.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}h${minutes}`
  }
}

async function concluirAula() {
  const idCurso = storeCursos.curso?._id
  const idModulo = storeModulos.modulo?._id
  const idAula = storeAulas.aula?._id

  const payload = {
    concluir: !state.aulaConcluida
  }

  if (idCurso && idModulo && idAula) {
    const concluidoOk = await storeAulasConcluidas.concluir(idCurso, idModulo, idAula, payload)
    if (concluidoOk !== true) return
    state.aulaConcluida = !state.aulaConcluida
  }
}

function irParaAula(hotlinkArea, hotlinkCurso, hotlinkModulo, hotlinkAula) {
  if (dominioPersonalizado()) {
    window.location.assign(`/curso/${hotlinkCurso}/${hotlinkModulo}/${hotlinkAula}`)
  } else {
    window.location.assign(`/${hotlinkArea}/curso/${hotlinkCurso}/${hotlinkModulo}/${hotlinkAula}`)
  }
}

function dominioPersonalizado() {
  const rotaAtual = window.location.hostname
  return !(rotaAtual.includes('localhost') || rotaAtual.includes('mber.live'))
}

onMounted(() => {
  emitter.on('atualizarComentarios', carregarComentarios)
})

watch(
  () => [storeCursos.curso?._id, storeModulos.modulo?._id, storeAulas.aula?._id, tela.value.corFundo, storeAreas.area?.personalizacao?.telaAula],
  ([idCurso, idModulo, idAula, corFundo, personalizacaoTelaAula]) => {
    if (idCurso && idModulo) {
      carregarAulas()
      carregarAulasConcluidas()
    }

    if (idCurso && idModulo && idAula) {
      carregarComentarios()
      verificarAulaConcluida()
    }

    if (corFundo) {
      document.body.style.backgroundColor = corFundo
      document.documentElement.style.backgroundColor = corFundo
    }

    if (personalizacaoTelaAula) {
      atualizarFonte()
    }
  },
  { immediate: true, deep: true }
)
</script>

<style scoped>
section.aula {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px;
  position: relative;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100dvh;
  padding: 0 0 0 300px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  animation: loading 1.5s ease;
}

@keyframes loading {
  0% {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  80% {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.loading span {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-top: 3px solid transparent !important;
  border: 3px solid;
  animation: girando 0.8s linear infinite;
}

.tela {
  display: flex;
  width: 100%;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
}

.conteudo.maior {
  width: 100%;
}

.principal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.principal .video {
  width: 100%;
}

.principal .video .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.principal .video .tamanho iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  border-radius: 10px;
}

.principal .opcoes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0 0 0;
}

.principal .opcoes button.voltar {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 50px;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  margin: 0 15px 0 0;
  transition: all 0.3s;
}

.principal .opcoes button.voltar:hover {
  opacity: 0.7;
}

.principal .opcoes button.voltar svg {
  width: 10px;
  min-width: 10px;
  margin: 0 10px 0 0;
  transform: rotate(180deg);
}

.principal .opcoes .posicao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #262836;
}

.principal .opcoes .posicao p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #ffffff;
}

.principal .opcoes .duracao {
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;
}

.principal .opcoes .duracao svg {
  width: 15px;
  min-width: 15px;
  fill: #64677c;
  margin: 0 5px 0 0;
}

.principal .opcoes .duracao p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #64677c;
}

.principal .opcoes button.concluir {
  display: flex;
  align-items: center;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  padding: 12px 25px;
  border-radius: 50px;
  margin-left: auto;
  transition: all 0.3s;
}

.principal .opcoes button.concluir svg {
  width: 15px;
  min-width: 15px;
  margin: 0 10px 0 0;
}

.principal .opcoes button.concluir:hover {
  transform: scale(1.1);
}

.principal .opcoes button.concluir.concluido {
  filter: brightness(60%);
}

.principal .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0 10px 0;
}

.principal .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f3);
  color: #262836;
  line-height: 1.5;
}

.principal .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.principal .links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0 0 0;
}

.principal .links h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  width: 100%;
  text-align: left;
  margin: 0 0 10px 0;
}

.principal .links a {
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 0 10px 10px 0;
}

.principal .links a svg {
  width: 15px;
  min-width: 15px;
  fill: #262836;
  margin: 0 10px 0 0;
}

.principal .links a p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  transition: all 0.3s;
}

.principal .links a:hover p {
  opacity: 0.7;
}

.principal .arquivos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0 0 0;
}

.principal .arquivos h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  width: 100%;
  text-align: left;
  margin: 0 0 10px 0;
}

.principal .arquivos a {
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 0 10px 10px 0;
}

.principal .arquivos a svg {
  width: 15px;
  min-width: 15px;
  fill: #262836;
  margin: 0 10px 0 0;
}

.principal .arquivos a p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  transition: all 0.3s;
}

.principal .arquivos a:hover p {
  opacity: 0.6;
}

.comentarios {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 30px 0 20px 0;
}

.comentarios .comentar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.comentarios .input {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.comentarios .input textarea {
  padding: 15px;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  border-radius: 10px;
  width: 100%;
  background-color: #ffffff;
  color: #262836;
  resize: none;
  border: none;
}

.comentarios .input .foto {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: 5px 20px 0 0;
}

.comentarios .botoes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 0 0 0;
}

.comentarios .botoes button.enviar {
  font-size: var(--f2);
  font-family: var(--fonte-tela);
  padding: 12px 20px;
  border-radius: 50px;
  color: #ffffff;
  background-color: #262836;
  transition: all 0.3s;
}

.comentarios .botoes button.enviar:hover {
  opacity: 0.7;
}

.comentarios .botoes button.cancelar {
  font-size: var(--f2);
  font-family: var(--fonte-tela);
  color: #84879c;
  background-color: transparent;
  padding: 10px;
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.comentarios .botoes button.cancelar:hover {
  opacity: 0.7;
}

.comentarios .respondendo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 15px 0 0 0;
}

.comentarios .respondendo .input {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 0 0;
}

.comentarios .comentario {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 0 55px;
  margin: 40px 0 0 0;
  position: relative;
}

.comentarios .comentario .foto {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  top: 0;
}

.comentarios .comentario .nome {
  display: flex;
  align-items: center;
}

.comentarios .comentario .nome h4 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  margin: 0 5px 0 0;
}

.comentarios .comentario .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  margin: 0 5px 0 0;
}

.comentarios .comentario .nome.produtor h3 {
  color: var(--cor-verde);
}

.comentarios .comentario .nome p {
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #84879c;
}

.comentarios .comentario .texto {
  display: flex;
  padding: 5px 0 0 0;
}

.comentarios .comentario .texto p {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  word-break: break-all;
  line-height: 1.5;
}

.comentarios .comentario button.responder {
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #262836;
  background-color: #ffffff;
  padding: 8px 15px;
  border-radius: 50px;
  margin: 10px 0 0 0;
  transition: all 0.3s;
}

.comentarios .comentario button.responder:hover {
  transform: scale(1.1);
}

.comentarios .resposta {
  position: relative;
  padding: 0 0 0 45px;
  margin: 30px 0 0 0;
}

.comentarios .resposta .foto {
  width: 35px;
  height: 35px;
}

.comentarios .resposta .nome h3 {
  font-size: var(--f1);
}

.comentarios .resposta .nome h4 {
  font-size: var(--f1);
}

.comentarios .resposta .texto p {
  font-size: var(--f1);
  word-break: break-all;
}

.aulas {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 0 0 0 20px;
}

.aulas .item {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 80px;
  margin: 0 0 10px 0;
  border-radius: 10px;
}

.aulas .item .capa {
  background-size: cover;
  background-position: center;
  border-radius: 10px 0 0 10px;
  width: 140px;
  min-width: 140px;
}

.aulas .item .info {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 0 10px 10px 0;
  padding: 20px;
  width: 100%;
  position: relative;
  z-index: 3;
}

.aulas .item .info h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #dfe3ed;
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  text-align: center;
}

.aulas .item .info .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 20px 0 10px;
}

.aulas .item .info .nome h3 {
  font-family: var(--fonte-tela);
  font-size: var(--f2);
  color: #262836;
  line-height: 1.5;
}

.aulas .item .info .nome p {
  display: flex;
  position: absolute;
  padding: 3px 5px;
  border-radius: 3px;
  bottom: 5px;
  left: -55px;
  font-family: var(--fonte-tela);
  font-size: var(--f1);
  color: #ffffff;
  background-color: #262836;
}

.aulas .item .info .botoes {
  display: flex;
  flex-direction: column;
}

.aulas .item .info .botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #262836;
  padding: 9px;
  border-radius: 10px;
  transition: all 0.3s;
}

.aulas .item .info .botoes button:hover {
  transform: scale(1.1);
}

.aulas .item .info .botoes button svg {
  width: 12px;
  min-width: 12px;
  fill: #ffffff;
}

@media screen and (max-width: 1000px) {
  section.aula {
    padding: 20px 20px 20px 20px;
  }

  .menu {
    display: none;
  }

  .loading {
    padding: 0;
  }

  .tela {
    flex-direction: column;
  }

  .conteudo {
    width: 100%;
  }

  .principal .video .tamanho .iframe svg {
    width: 30px;
    min-width: 30px;
    margin: 0 0 15px 0;
  }

  .principal .video .tamanho .iframe p {
    font-size: var(--f2);
  }

  .principal .opcoes {
    margin: 15px 0 0 0;
  }

  .principal .opcoes .posicao {
    width: 35px;
    height: 35px;
  }

  .principal .opcoes .posicao p {
    font-size: var(--f1);
  }

  .principal .opcoes .duracao {
    margin: 0 0 0 15px;
  }

  .principal .opcoes .duracao svg {
    width: 13px;
    min-width: 13px;
  }

  .principal .opcoes .duracao p {
    font-size: var(--f1);
  }

  .principal .nome h3 {
    font-size: var(--f2);
  }

  .principal .nome p {
    font-size: var(--f1);
  }

  .principal .links {
    padding: 15px 0 0 0;
  }

  .principal .links h3 {
    font-size: var(--f1);
  }

  .principal .links a svg {
    width: 12px;
    min-width: 12px;
    margin: 0 8px 0 0;
  }

  .principal .links a p {
    font-size: var(--f1);
  }

  .principal .arquivos {
    padding: 15px 0 0 0;
  }

  .principal .arquivos h3 {
    font-size: var(--f1);
  }

  .principal .arquivos a svg {
    width: 12px;
    min-width: 12px;
    margin: 0 8px 0 0;
  }

  .principal .arquivos a p {
    font-size: var(--f1);
  }

  .comentarios {
    padding: 20px 0 20px 0;
  }

  .comentarios .input textarea {
    padding: 15px;
  }

  .comentarios .input .foto {
    width: 35px;
    height: 35px;
    min-width: 35px;
    margin: 15px 15px 0 0;
  }

  .comentarios .botoes button.enviar {
    font-size: var(--f1);
  }

  .comentarios .botoes button.cancelar {
    font-size: var(--f1);
  }

  .comentarios .botoes button.cancelar:hover {
    opacity: 0.7;
  }

  .comentarios .comentario {
    padding: 0 0 0 50px;
    margin: 35px 0 0 0;
  }

  .comentarios .comentario .foto {
    width: 35px;
    min-width: 35px;
    height: 35px;
  }

  .comentarios .resposta {
    padding: 0 0 0 40px;
    margin: 25px 0 0 0;
  }

  .comentarios .resposta .foto {
    width: 30px;
    min-width: 30px;
    height: 30px;
  }

  .aulas {
    width: 100%;
    padding: 20px 0 0 0;
  }

  .aulas .item {
    min-height: 70px;
  }

  .aulas .item .capa {
    width: 110px;
    min-width: 110px;
  }

  .aulas .item .info {
    padding: 15px;
  }

  .aulas .item .info h5 {
    width: 25px;
    height: 25px;
    font-size: var(--f1);
  }

  .aulas .item .info .nome {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: auto;
    padding: 0 20px 0 10px;
  }

  .aulas .item .info .nome h3 {
    font-size: var(--f1);
  }
}
</style>
